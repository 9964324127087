import styled from 'styled-components'
import { white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Container = styled.div`
  background-color: ${white};
  margin: -90px -24px 0;
  border-radius: 16px;
  padding: 24px;
  position: relative;
  z-index: 1;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);

  @media ${device.tablet} {
    padding: 40px 30px;
    margin: -90px 0 0;
  }
  @media ${device.desktopLG} {
    padding: 40px;
  }
  @media ${device.desktopXXXL} {
    padding-left: 220px;
    padding-right: 220px;
  }

  img {
    @media ${device.tablet} {
      max-width: 177px;
    }
  }

  .form-label-check {
    &:before {
      top: 34px;
      @media ${device.desktopLG} {
        top: 27px;
      }
      @media ${device.desktopXL} {
        top: 17px;
      }
    }
  }

  .form-input-check {
    &:checked ~ label {   
    &::after {
      top: 37px;  
        @media ${device.desktopLG} {
          top: 30px;
        } 
        @media ${device.desktopXL} {
          top: 20px;
        }  
      }
    }
  }

  .input-error {
    label, p {
      color: #F56A50;
    }
    input {
      background-color: #FEF0ED;
    }
  }
`
