import styled from 'styled-components'
import { orange } from 'src/styles/colors'
import { device, breakpoints } from 'src/styles/breakpoints'
import BackgroundImage from 'gatsby-background-image'

export const Wrapper = styled.div`
  .open-account-fixed {
    display: none;
  }
  .btn-open-account {
    display: none;
  }
`

export const ContentHeader = styled.section`
  background-color: ${orange.extra};
  padding-top: 40px;
  padding-bottom: 90px;

  @media (min-width: ${breakpoints.lg}) {
    padding-top: 70px;
    h2 {
       padding-top: 40px;
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    h2 {
       padding-top: 90px;
    }
  }
`
export const IconService = styled.span`
  height: 44px;
  width: 44px;
`

export const SomosMaisSection = styled(BackgroundImage)`
  min-height: 520px;

  &::before, &::after {
    max-height: 230px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }

  @media ${device.tablet} {
    min-height: 280px;

    &::before, &::after {
      width: 345px !important;
      max-height: none;
    }
  }

  @media ${device.desktopLG} {
    min-height: 370px;

    &::before, &::after {
      width: 460px !important;
    }
  }

  @media ${device.desktopXL} {
    min-height: 450px !important;

    &::before, &::after {
      background-size: contain !important;
      width: 730px !important;
    }
  }
`
