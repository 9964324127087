import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      bannerDesktop: imageSharp(fluid: { originalName: { regex: "/convidar-amigos-voce-recebeu-um-convite-desktop/" }}) {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerMobile: imageSharp(fluid: { originalName: { regex: "/convidar-amigos-voce-recebeu-um-convite-mobile/" }}) {
        fluid(maxWidth: 480, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      somosMaisQueUmBancoDesktop: imageSharp(fluid: { originalName: { regex: "/convidar-amigos-somos-mais-que-um-banco-desktop/" }}) {
        fluid(maxWidth: 730, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      somosMaisQueUmBancoMobile: imageSharp(fluid: { originalName: { regex: "/convidar-amigos-somos-mais-que-um-banco-mobile/" }}) {
        fluid(maxWidth: 225, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
