import React from 'react'
import Img from 'gatsby-image'

// Components
import Layout from 'src/layouts/BaseLayout'
import OpenAccountInvitationForm from 'src/components/UI/Forms/OpenAccountInvitationForm'
import Icon from 'src/components/UI/MarkdownIcon'
import ScrollTo from 'src/components/ScrollTo'

// PageContext
import pageContext from './pageContext.json'
import usePageQuery from './pageQuery'

// Styles
import { Wrapper, ContentHeader, IconService, SomosMaisSection } from './style'

const ConvidarAmigos: React.FC = () => {
  const data = usePageQuery()

  return (
    <Wrapper>

      <Layout pageContext={pageContext}>
        <ContentHeader>
          <div className='container'>
            <div className='row align-items-center flex-column-reverse flex-md-row'>
              <div className='col-12 col-md-6 col-xl-5'>
                <Img fluid={data.bannerDesktop.fluid} />
              </div>
              <div className='col-12 col-md-6 col-xl-7'>
                <h2 className='fs-24 fs-lg-40 fw-600 lh-30 lh-lg-50 text-white mb-3'>Você recebeu um convite para  abrir uma conta digital gratuita</h2>
                <p className='fs-14 fs-lg-18 fw-40 lh-17 lh-lg-22 text-white mb-0'>Preencha o formulário abaixo e junte-se a mais de 14 milhões de pessoas que aproveitam de todos os benefícios e vantagens que o Super App do Inter oferece.</p>
              </div>
            </div>
          </div>
        </ContentHeader>
        <section id='abra-ja-sua-conta-digital'>
          <div className='container'>
            <OpenAccountInvitationForm />
          </div>
        </section>
        <section id='todos-os-servicos-que-voce-precisa' className='py-4 py-md-5'>
          <div className='container'>
            <div className='row align-items-center justify-content-between'>
              <div className='col-12 col-md-8 col-lg-5'>
                <h3 className='fs-20 fs-md-24 fs-lg-32 fs-xl-40 fw-600 text-grayscale--500 mt-2 mb-3'>Todos os serviços que você precisa, online e sem burocracia!</h3>
              </div>
              <div className='col-12 col-lg-5'>
                <div className='row align-items-start justify-content-between'>
                  <div className='col-6 col-md-4 col-lg-6 my-4 my-md-3 my-xl-4'>
                    <IconService className='bg-grayscale--100 rounded-100 mb-2 d-flex align-items-center justify-content-center'>
                      <Icon width={24} height={24} directory='v2' icon='pagamentos/deposito-cheque' />
                    </IconService>
                    <h4 className='fs-16 fs-xl-20 lh-20 lh-lg-25 fw-600 text-grayscale--500 mb-0'>Pix e TEDs ilimitados</h4>
                  </div>
                  <div className='col-6 col-md-4 col-lg-6 my-4 my-md-3 my-xl-4'>
                    <IconService className='bg-grayscale--100 rounded-100 mb-2 d-flex align-items-center justify-content-center'>
                      <Icon width={24} height={24} directory='v2' icon='products/banco/pessoa-fisica' />
                    </IconService>
                    <h4 className='fs-16 fs-xl-20 lh-20 lh-lg-25 fw-600 text-grayscale--500 mb-0'>Atendimento 24h</h4>
                  </div>
                  <div className='col-6 col-md-4 col-lg-6 my-4 my-md-3 my-xl-4'>
                    <IconService className='bg-grayscale--100 rounded-100 mb-2 d-flex align-items-center justify-content-center'>
                      <Icon width={24} height={24} directory='v2' icon='products/credito/envio-remessa' />
                    </IconService>
                    <h4 className='fs-16 fs-xl-20 lh-20 lh-lg-25 fw-600 text-grayscale--500 mb-0'>Depósito de dinheiro por boleto</h4>
                  </div>
                  <div className='col-6 col-md-4 col-lg-6 my-4 my-md-3 my-xl-4'>
                    <IconService className='bg-grayscale--100 rounded-100 mb-2 d-flex align-items-center justify-content-center'>
                      <Icon width={24} height={24} directory='v2' icon='products/investimentos/poupança-2' />
                    </IconService>
                    <h4 className='fs-16 fs-xl-20 lh-20 lh-lg-25 fw-600 text-grayscale--500 mb-0'>Plataforma de investimentos</h4>
                  </div>
                  <div className='col-6 col-md-4 col-lg-6 my-4 my-md-3 my-xl-4'>
                    <IconService className='bg-grayscale--100 rounded-100 mb-2 d-flex align-items-center justify-content-center'>
                      <Icon width={24} height={24} directory='v2' icon='pagamentos/card-money' />
                    </IconService>
                    <h4 className='fs-16 fs-xl-20 lh-20 lh-lg-25 fw-600 text-grayscale--500 mb-0'>Saques gratuitos e ilimitados</h4>
                  </div>
                  <div className='col-6 col-md-4 col-lg-6 my-4 my-md-3 my-xl-4'>
                    <IconService className='bg-grayscale--100 rounded-100 mb-2 d-flex align-items-center justify-content-center'>
                      <Icon width={24} height={24} directory='v2' icon='products/banco/conta-pj' />
                    </IconService>
                    <h4 className='fs-16 fs-xl-20 lh-20 lh-lg-25 fw-600 text-grayscale--500 mb-0'>Conta digital gratuita</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SomosMaisSection
          Tag='section'
          fluid={[
            data.somosMaisQueUmBancoDesktop.fluid,
            {
              ...data.somosMaisQueUmBancoMobile.fluid,
              media: '(max-width: 767px)',
            },
          ]}
          id='somos-mais-que-um-banco'
          className='bg-orange--extra d-flex py-5 align-items-end align-items-md-center'
        >
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-12 col-md-6' />
              <div className='col-12 col-md-6 col-xl-5'>
                <h3 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-lg-50 fw-600 text-white'>Somos mais que um banco. Somos Inter!</h3>
                <p className='fs-14 fs-lg-18 lh-17 lh-lg-22 text-white mb-3'>Sua conta também te dá acesso à toda nossa plataforma com produtos como <strong>seguro, shopping com cashback</strong> e muito mais.</p>
                <ScrollTo to='#abra-ja-sua-conta-digital' styles='btn bg-white text-orange--extra rounded-2 btn--lg fs-14 fw-600 text-none mt-3'>
                  Quero criar minha conta
                </ScrollTo>
              </div>
            </div>
          </div>
        </SomosMaisSection>
      </Layout>
    </Wrapper>
  )
}

export default ConvidarAmigos
