import React, { useState, useEffect, MouseEvent, ChangeEvent } from 'react'
import axios from 'axios'
import { Link } from 'gatsby'
import { useForm, UseFormMethods } from 'react-hook-form'
import { differenceInYears } from 'date-fns'
import useWidth from 'src/hooks/window/useWidth'

import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'

import { getParameterByName, sendDataLayerEvent } from 'src/shared/helpers'
import AcceptTerms from 'src/components/AcceptTerms/OpenAccount'

import errorImg from 'src/assets/images/shared/erro-pig.png'
import QRCodeBaixeOApp from 'src/assets/images/qrcode-header.jpg'
import sucesso from '../images/sucesso.png'

import { Container } from './style'

interface IFormValues {
  nome: string;
  celular: string;
  email: string;
  cpf: string;
  dataNascimento: string;
}

function OpenAccountInvitationForm () {
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const formStatus = {
    idle: 'idle',
    loading: 'loading',
    sent: 'sent',
    error: 'error',
    filled: 'filled',
    underAge: 'underAge',
  }
  const [ status, setStatus ] = useState(formStatus.idle)
  const [ accept, setAccept ] = useState(false)
  const inviteCode = getParameterByName('c')
  const WIDTH_MD = 1024
  const windowWidth = useWidth(200)
  const [ utmSource, setUtmSource ] = useState<string | string[] | null | undefined>(null)
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  const [ utmContent, setUtmContent ] = useState<string | string[] | null | undefined>(null)

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmContent(getParameterByName('utm_content', ''))
  }, [])

  const sendForm = async (data: IFormValues) => {
    setStatus(formStatus.loading)
    const newDate = data.dataNascimento.replace(/\D/g, ',').split(',').reverse().join(',')
    const result = differenceInYears(new Date(), new Date(newDate))

    const formData = {
      ...data,
      cpf: data.cpf.replace(/\D/g, ''),
      celular: data.celular.replace(/\D/g, ''),
      dataNascimento: data.dataNascimento.replace(/\D/g, ',').split(',').reverse().join('-'),
      termoAceito: true,
      utmCampaign: utmCampaign?.toString(),
      utmMedium: utmMedium?.toString(),
      utmSource: utmSource?.toString(),
      utmContent: utmContent?.toString(),
    }

    const headers = inviteCode && {
      'x-inter-codigo-convite': inviteCode,
    }

    try {
      if (result < 18) {
        setStatus(formStatus.underAge)
      } else {
        await axios.put(`${URLS.OPEN_ACCOUNT_V2}`, formData, {
          headers,
        })
        setStatus(formStatus.sent)
        sendDataLayerEvent('form_submitted')
      }
    } catch (e) {
      setStatus(formStatus.error)
    }
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setStatus(formStatus.idle)
  }

  const asFilled = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    setStatus(formStatus.filled)
  }

  const asUnderAge = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    if (windowWidth < WIDTH_MD) {
      window.location.href = 'https://intergo.app/7820e737'
    } else {
      window.location.href = '/pra-voce/conta-digital/conta-kids/'
    }
  }

  return (
    <>
      { status === formStatus.error && (
        <Container className='d-flex align-items-center'>
          <div className='col-8 mx-auto'>
            <div className='row align-items-center justify-content-between py-3'>
              <div className='col-12 col-md-4 my-2 my-md-0'>
                <img src={errorImg} alt='Imagem de erro' />
              </div>
              <div className='col-12 col-md-7 my-2 my-md-0'>
                <p className='fs-16 lh-20 fw-600 text-grayscale--400 mb-3'>
                  Ah não! Ocorreu um erro com a sua solicitação...
                </p>
                <p className='fs-16 lh-20 fw-600 text-grayscale--500 mb-0'>
                  Verifique a sua conexão com a internet e tente novamente.
                  <button onClick={handleReturn} title='Clique aqui para voltar' className='bg-transparent text-orange--extra fw-600 border-0'>
                    Clique aqui
                  </button>para voltar.
                </p>
              </div>
            </div>
          </div>
        </Container>
      )}
      { status === formStatus.sent && (
        <Container>
          <div className='col-11 col-lg-9 col-xl-8 mx-auto'>
            <div className='row align-items-center justify-content-between py-3 py-lg-2'>
              <div className='col-12 col-md-4 my-2 my-md-0 px-lg-0'>
                <img className='d-none d-md-block' src={QRCodeBaixeOApp} alt='Baixe o App Inter' />
              </div>
              <div className='col-12 col-md-7 my-2 my-md-0'>
                <p className='fs-16 lh-20 fw-600 text-grayscale--400 mb-3'>
                  Prontinho! Recebemos seus dados.
                </p>
                <p className='fs-24 lh-28 fw-600 text-grayscale--500 mb-0'>
                  Agora <strong className='text-orange--extra'>baixe nosso app</strong> <span className='d-lg-block'>e abra sua conta gratuita.</span>
                </p>
                <a
                  className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none mt-4 d-lg-none'
                  href='https://intergo.app/7820e737'
                >Baixar o app agora
                </a>
              </div>
            </div>
            <p className='fs-14 lh-17 text-grayscale--400 mt-3 d-none d-lg-block border-lg-top pt-lg-4'>
              Abra a câmera do seu celular e aponte para o QR Code acima. É só baixar o app do Inter e clicar
              em “Abrir conta completa e gratuita”. É rápido e fácil.
            </p>
          </div>
        </Container>
      )}
      { status === formStatus.underAge && (
        <Container>
          <div className='col-12 col-lg-9 col-xl-8 mx-auto text-center text-md-left'>
            <div className='row align-items-center justify-content-center justify-content-lg-between py-3 py-lg-2'>
              <div className='col-12 col-md-4 my-2 my-md-0 px-lg-0'>
                <img className='d-none d-md-block d-lg-none' src={sucesso} alt='Sucesso' />
                <img className='d-none d-lg-block' src={QRCodeBaixeOApp} alt='Baixe o App Inter' />
              </div>
              <div className='col-12 col-md-8 my-2 my-md-0'>
                <p className='fs-16 lh-20 fw-600 text-grayscale--400 mb-3'>
                  Vimos que você tem menos de 18 anos.
                </p>
                <p className='fs-20 lh-24 fs-md-24 lh-md-28 fw-600 text-grayscale--500 mb-0'>
                  Conheça nossa
                  <span
                    className='text-orange--extra cursor-pointer'
                    onClick={asUnderAge}
                  > Conta Kids
                  </span>, que também é completa, digital e gratuita.
                </p>
              </div>
            </div>
            <p className='fs-14 lh-17 text-grayscale--400 mt-3 d-none d-lg-block border-lg-top pt-lg-4'>
              Abra a câmera do seu celular e aponte para o QR Code acima. É só baixar o app do Inter e clicar
              em “Abrir conta completa e gratuita”. É rápido e fácil!
            </p>
            <p className='fs-14 lh-17 text-grayscale--400 mt-3 d-none d-lg-block'>
              De acordo com nossa Política de Privacidade, não armazenamos dados de menores de 18 anos
              sem o consentimento dos responsáveis.
            </p>
            <div className='col-12 col-md-4 my-2 my-md-0 px-lg-0 d-md-none'>
              <img src={sucesso} alt='Sucesso' />
            </div>
          </div>
        </Container>
      )}
      { status === formStatus.filled && (
        <Container className='d-flex align-items-center'>
          <div className='col-11 col-md-10 col-xl-8 mx-auto'>
            <div className='row align-items-center justify-content-between border-lg-bottom py-3'>
              <div className='col-12 col-md-4 my-2 my-md-0'>
                <img className='d-none d-md-block' src={QRCodeBaixeOApp} alt='Baixe o App Inter' />
              </div>
              <div className='col-12 col-md-7 my-2 my-md-0'>
                <p className='fs-24 lh-30 fw-600 text-grayscale--500 mb-0'>
                  <strong className='text-orange--extra'>Baixe o app </strong> e vá em <br /> “Abrir uma conta gratuita”
                </p>
                <Link
                  to='/pra-voce/conta-digital/pessoa-fisica/'
                  className='fs-16 fw-600 lh-20 text-orange--extra pt-4 d-block'
                >Saiba mais sobre a Conta Digital PF
                </Link>
                <a
                  className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none mt-4 d-xl-none'
                  href='https://intergo.app/7820e737'
                >Baixar o app agora
                </a>
              </div>
            </div>
            <p className='fs-14 lh-17 text-grayscale--400 mt-3 d-none d-lg-block'>
              Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para a loja de aplicativos.
              Aí é só baixar o app do Inter e abrir a sua conta.
            </p>
          </div>
        </Container>
      )}
      <Container className={`${ (status === formStatus.idle || status === formStatus.loading) ? 'd-flex' : 'd-none'} align-items-center`}>
        <div className='row'>
          <div className='col-12 text-center'>
            <h3 className='fs-20 fs-lg-24 lh-lg-30 mb-2 lh-24 text-gray--800'>Abra já a sua Conta Digital</h3>
          </div>
          <div className='col-12'>
            <form className='form--default' name='open_account' onSubmit={handleSubmit(sendForm)}>
              <div className='row mt-3'>
                <div className={`col-12 col-md-6 pb-3 px-0 px-md-3 ${errors.nome && 'input-error'}`}>
                  <label htmlFor='nome' className='fs-14 lh-18 d-block'>Nome</label>
                  <input
                    className='w-100'
                    ref={register({
                      required: 'Por favor, digite seu nome completo',
                      validate: {
                        isName: (value: string) => Validations.name(value) || 'Por favor, digite seu nome completo',
                      },
                    })}
                    name='nome'
                    id='nome'
                    type='text'
                    maxLength={100}
                    placeholder='Digite seu nome'
                  />
                  {errors.nome && <p className='fs-12 text-right mb-0'>{errors.nome.message}</p>}
                </div>
                <div className={`col-12 col-md-6 pb-3 px-0 px-md-3 ${errors.celular && 'input-error'}`}>
                  <label htmlFor='celular' className='fs-14 lh-18 d-block'>Telefone</label>
                  <input
                    className='w-100'
                    ref={register({
                      required: 'Digite um telefone válido',
                      pattern: {
                        value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                        message: 'Telefone inválido',
                      },
                    })}
                    name='celular'
                    id='celular'
                    type='tel'
                    placeholder='Digite seu telefone'
                    maxLength={15}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('celular', Masks.MaskPHONE(event.currentTarget.value))}
                  />
                  { errors.celular && <p className='fs-12 text-right mb-0'>{errors.celular.message}</p> }
                </div>
                <div className={`col-12 col-md-6 pb-3 px-0 px-md-3 ${errors.email && 'input-error'}`}>
                  <label htmlFor='email' className='fs-14 lh-18 d-block'>E-mail</label>
                  <input
                    className='w-100'
                    ref={register({
                      required: 'Digite um e-mail válido',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'E-mail inválido',
                      },
                    })}
                    name='email'
                    id='email'
                    type='text'
                    placeholder='Digite seu e-mail'
                  />
                  { errors.email && <p className='fs-12 text-right mb-0'>{errors.email.message}</p> }
                </div>
                <div className={`col-12 col-md-6 d-flex flex-column pb-3 px-0 px-md-3 ${errors.cpf && 'input-error'}`}>
                  <label htmlFor='cpf' className='fs-14 lh-18'>CPF</label>
                  <input
                    className='w-100'
                    ref={register({
                      required: 'Digite um CPF válido',
                      validate: {
                        isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                      },
                    })}
                    name='cpf'
                    id='cpf'
                    type='tel'
                    placeholder='Digite seu CPF'
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPF(event.currentTarget.value))}
                  />
                  {errors.cpf && <p className='fs-12 text-right mb-0'>{errors.cpf.message}</p>}
                </div>
                <div className={`col-12 col-md-6 d-flex flex-column px-0 px-md-3 ${errors.dataNascimento && 'input-error'}`}>
                  <label htmlFor='dataNascimento' className='fs-14 lh-18'>Data de Nascimento</label>
                  <input
                    ref={register({
                      required: 'Digite uma data válida',
                      validate: {
                        isDate: (value: string) => Validations.dateValid(value) || 'Data Inválida',
                      },
                    })}
                    name='dataNascimento'
                    id='dataNascimento'
                    type='tel'
                    placeholder='dd/mm/aaaa'
                    maxLength={10}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('dataNascimento', Masks.MaskDATE(event.currentTarget.value))}
                  />
                  {errors.dataNascimento && <p className='fs-12 text-red--base mb-0 text-right'>{errors.dataNascimento.message}</p>}
                </div>
                <div className='col-12 col-md-6 d-flex flex-column pt-2 pt-md-0 px-0 px-md-3 mt-md-n2 mt-lg-n1 mt-xl-2'>
                  <AcceptTerms accept={accept} setAccept={setAccept} name='conta-digital-pf' />
                </div>
                <div className='col-12 text-center mt-2 mt-md-4 pt-lg-2'>
                  <button
                    type='submit' title='Enviar contato' disabled={!accept || status === formStatus.loading}
                    className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none'
                  >
                    {status === formStatus.loading ? 'Enviando...' : 'Continuar'}
                  </button>
                  <Link
                    to='/pra-voce/conta-digital/pessoa-fisica/'
                    className='fs-16 fw-600 lh-20 text-orange--extra pt-4 d-block'
                  >Saiba mais sobre a Conta Digital PF
                  </Link>
                </div>
              </div>
            </form>
            <div className='text-center pt-3'>
              <span className='fs-14 lh-18 text-grayscale--500'>
                Já preencheu? <button onClick={asFilled} className='text-orange--extra fw-600 bg-transparent border-0 pl-0'>Agora baixe o app</button>
              </span>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default OpenAccountInvitationForm
